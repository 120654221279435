import gql from 'graphql-tag'
import { ADDRESS_MODEL_FRAGMENT } from '@/modules/graphql/AddressModelFragment'
import { PERSON_MODEL_FRAGMENT } from '@/modules/graphql/PersonModelFragment'

export const COMPANY_MODEL_FRAGMENT = gql`
  ${ADDRESS_MODEL_FRAGMENT}
  ${PERSON_MODEL_FRAGMENT}

  fragment CargoledgerCompanyModelFields on CargoledgerCompanyModel {
    id
    address {
      ...AddressModelFields
    }
    cargoledger
    name
    person {
      ...PersonModelFields
    }
  }
`
