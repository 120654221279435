import gql from 'graphql-tag'
import { DIMENSION_MODEL_FRAGMENT } from '@/modules/graphql/DimensionModelFragment'
import { SHIPMENT_COMPANY_MODEL_FRAGMENT } from '@/modules/graphql/ShipmentCompanyModelFragment'
import { ADDRESS_MODEL_FRAGMENT } from '@/modules/graphql/AddressModelFragment'
import {PERSON_MODEL_FRAGMENT} from "@/modules/graphql/PersonModelFragment";
import {DOCUMENT_MODEL_FRAGMENT} from "@/modules/graphql/DocumentModelFragment";
import {MAP_STRING_STRING_MODEL_FRAGMENT} from "@/modules/graphql/MapStringStringModelFragment";
import {MAP_STRING_INT_MODEL_FRAGMENT} from "@/modules/graphql/MapStringIntModelFragment";
import {HAND_OVER_MODEL_FRAGMENT} from "@/modules/graphql/HandOverModelFragment";
import {ACTION_MODEL_FRAGMENT} from "@/modules/graphql/ActionModelFragment";
import {ITEM_MODEL_FRAGMENT} from "@/modules/graphql/ItemModelFragment";
import {STATUS_MODEL_FRAGMENT} from "@/modules/graphql/StatusModelFragment";

export const CONSIGNMENT_MODEL_FRAGMENT = gql`
  ${DIMENSION_MODEL_FRAGMENT}
  ${SHIPMENT_COMPANY_MODEL_FRAGMENT}
  ${ADDRESS_MODEL_FRAGMENT}
  ${PERSON_MODEL_FRAGMENT}
  ${DOCUMENT_MODEL_FRAGMENT}
  ${MAP_STRING_STRING_MODEL_FRAGMENT}
  ${MAP_STRING_INT_MODEL_FRAGMENT}
  ${HAND_OVER_MODEL_FRAGMENT}
  ${ACTION_MODEL_FRAGMENT}
  ${ITEM_MODEL_FRAGMENT}
  ${STATUS_MODEL_FRAGMENT}


  fragment ConsignmentModelFields on ConsignmentModel {
    id
      
    shipment {
        id
        identifier
        name
        expeditor {
            id
            cargoledger
        }
        actorId
    }  
      
    actors {
      id
      price {
        date
        id
        currency
        amount
      }
      company {
        ...ShipmentCompanyModelFields
      }
      delivery {
        ...ActionModelFields
      }
      pickup {
        ...ActionModelFields
      }
      progresses {
        id
        status {
          ...StatusModelFields
        }
      }
    }
    
    references

    containers {
      id
      barcode
      dimensions {
        ...DimensionModelFields
      }
      identifier
      packets {
        id
        description
        dimensions {
          ...DimensionModelFields
        }
        identifier
        quantity
        items {
          ...ItemModelFields
        }
      }
      properties {
        ...MapStringStringModelFields
      }
      quantity
      type {
        size
        type
      }
    }

    customs
    documents {
      ...DocumentModelFields
    }
    id
    name
    indicators
    receiver {
      ...ShipmentCompanyModelFields
    }
    sender {
      ...ShipmentCompanyModelFields
    }
  }
`
