import gql from 'graphql-tag'

export const PERSON_MODEL_FRAGMENT = gql`
  fragment PersonModelFields on PersonModel {
    email
    id
    name
    phone
  }
`
