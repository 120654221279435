import gql from 'graphql-tag'
import {ADDRESS_MODEL_FRAGMENT} from "@/modules/graphql/AddressModelFragment";
import {PERSON_MODEL_FRAGMENT} from "@/modules/graphql/PersonModelFragment";
import {MAP_STRING_STRING_MODEL_FRAGMENT} from "@/modules/graphql/MapStringStringModelFragment";
import {DOCUMENT_MODEL_FRAGMENT} from "@/modules/graphql/DocumentModelFragment";
import {MAP_STRING_INT_MODEL_FRAGMENT} from "@/modules/graphql/MapStringIntModelFragment";
import {HAND_OVER_MODEL_FRAGMENT} from "@/modules/graphql/HandOverModelFragment";
import {STATUS_MODEL_FRAGMENT} from "@/modules/graphql/StatusModelFragment";
import {PAIR_STATUS_REQUIREMENT_MODEL_FRAGMENT} from "@/modules/graphql/PairStatusRequirementModelFragment";

export const ACTION_MODEL_FRAGMENT = gql`
  ${ADDRESS_MODEL_FRAGMENT}
  ${PERSON_MODEL_FRAGMENT}
  ${MAP_STRING_STRING_MODEL_FRAGMENT}
  ${DOCUMENT_MODEL_FRAGMENT}
  ${MAP_STRING_INT_MODEL_FRAGMENT}
  ${HAND_OVER_MODEL_FRAGMENT}
  ${PAIR_STATUS_REQUIREMENT_MODEL_FRAGMENT}
  ${STATUS_MODEL_FRAGMENT}

  
  fragment ActionModelFields on ActionModel {
    id
    identifier
    address {
      ...AddressModelFields
    }
    eta
    etd
    instructions
    person {
      ...PersonModelFields
    }
    requirements {
      ...PairStatusRequirementModelFields
    }
    status {
      ...StatusModelFields
    }
  }
`
