import { render, staticRenderFns } from "./Popup.vue?vue&type=template&id=28eb25c4&scoped=true&"
import script from "./Popup.vue?vue&type=script&lang=ts&"
export * from "./Popup.vue?vue&type=script&lang=ts&"
import style0 from "./Popup.vue?vue&type=style&index=0&id=28eb25c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28eb25c4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VDialog,VDivider,VIcon})
