import gql from 'graphql-tag'

export const SHIPMENT_COMPANY_MODEL_FRAGMENT = gql`
  fragment ShipmentCompanyModelFields on ShipmentCompanyModel {
  id
    actorId
    shared
    address {
      ...AddressModelFields
    }
    cargoledger
    name
    person {
      ...PersonModelFields
    }
  }
`
