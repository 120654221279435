
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class InfoCard extends Vue {
  @Prop({ required: true }) title!: string
  @Prop({ default: true }) translation!: boolean
  @Prop({ default: false, type: Boolean }) noMargin!: boolean
  @Prop({ default: '' }) subtitle!: string
}
