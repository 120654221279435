import gql from 'graphql-tag'

export const ITEM_MODEL_FRAGMENT = gql`
  fragment ItemModelFields on ItemModel {
    id
    description
    hscode
    identifier
    properties {
      ...MapStringStringModelFields
    }
    size
  }
`
