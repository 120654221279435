import { Component, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import {ListShipments, ListShipments_ListShipments} from '@/generated/ListShipments'
import { GetShipments, GetShipments_GetShipment } from '@/generated/GetShipments'
import { ApolloQueryResult } from 'apollo-client'
import { CONSIGNMENT_MODEL_FRAGMENT } from '@/modules/graphql/ConsignmentModelFragment'
import { SHIPMENT_COMPANY_MODEL_FRAGMENT } from '@/modules/graphql/ShipmentCompanyModelFragment'
import { ADDRESS_MODEL_FRAGMENT } from '@/modules/graphql/AddressModelFragment'
import { DIMENSION_MODEL_FRAGMENT } from '@/modules/graphql/DimensionModelFragment'
import { PERSON_MODEL_FRAGMENT } from '@/modules/graphql/PersonModelFragment'
import { DOCUMENT_MODEL_FRAGMENT } from '@/modules/graphql/DocumentModelFragment'
import { COMPANY_MODEL_FRAGMENT } from '@/modules/graphql/CompanyModelFragment'
import { MAP_STRING_STRING_MODEL_FRAGMENT } from '@/modules/graphql/MapStringStringModelFragment'
import { MAP_STRING_INT_MODEL_FRAGMENT } from '@/modules/graphql/MapStringIntModelFragment'
import { HAND_OVER_MODEL_FRAGMENT } from '@/modules/graphql/HandOverModelFragment'
import { GenericImport, Pagination } from '@/generated/globalTypes'
import { GraphqlCreator } from '@/generated/GraphqlCreator'

@Component
export default class ShipmentService extends Vue {
  private listShipments = gql`
    query ListShipments($pagination: Pagination!, $finished: Boolean!) {
      ListShipments(a0: $pagination, a1: $finished) {
            items {
                ata
                created
                updated
                eta
                finished
                id
                name
                consignments {
                  actors {
                    delivery {
                      status {
                        type
                        created
                        location
                        documents {
                          type
                          name
                        }                        
                      }
                    }
                    pickup {
                      status {
                        type
                        created
                        location
                        documents {
                          type
                          name
                        }                        
                      }
                    }
                    progresses {
                      id
                      status {
                        type
                        created
                        location
                        documents {
                          type
                          name
                        }
                      }
                    }                              
                  }
                  documents {
                      type
                      name
                    }
                }
            }
       
        total
      }
    }
  `
  getShipmentOverview(entityID: number | null = null, pagination: Pagination): Promise<ListShipments_ListShipments> {
    return this._getShipmentOverview(false, pagination)
  }
  getFinishedShipmentOverview(entityID: number | null = null, pagination: Pagination): Promise<ListShipments_ListShipments> {
    return this._getShipmentOverview(true, pagination)
  }
  private _getShipmentOverview(finished: boolean, pagination: Pagination): Promise<ListShipments_ListShipments> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.listShipments,
          variables: {
            finished: finished,
            pagination: pagination
          }
        })
        .then((result: ApolloQueryResult<ListShipments>) => {
          resolve(result.data.ListShipments)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  getShipment(id: number): Promise<GetShipments_GetShipment> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.getShipmentQuery,
          variables: {
            id: id
          }
        })
        .then((result: ApolloQueryResult<GetShipments>) => {
          resolve(result.data.GetShipment)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  private getShipmentQuery = gql`
    ${CONSIGNMENT_MODEL_FRAGMENT}
    ${SHIPMENT_COMPANY_MODEL_FRAGMENT}
    ${ADDRESS_MODEL_FRAGMENT}
    ${DIMENSION_MODEL_FRAGMENT}
    ${PERSON_MODEL_FRAGMENT}
    ${DOCUMENT_MODEL_FRAGMENT}
    ${COMPANY_MODEL_FRAGMENT}
    ${MAP_STRING_STRING_MODEL_FRAGMENT}
    ${MAP_STRING_INT_MODEL_FRAGMENT}
    ${HAND_OVER_MODEL_FRAGMENT}

    query GetShipments($id: Int!) {
      GetShipment(a0: $id) {
        ...ShipmentModelFields
      }
    }

    fragment ShipmentModelFields on ShipmentModel {
      id
      actorId
      ata
      consignments {
        ...ConsignmentModelFields
      }
      expeditor {
        ...CargoledgerCompanyModelFields
      }
    }
  `

  graphqlCreate(gImport: GenericImport): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.graphqlCreator,
          variables: {
            gImport: gImport
          }
        })
        .then((result: ApolloQueryResult<GraphqlCreator>) => {
          resolve(result.data.GraphqlCreator)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  private graphqlCreator = gql`
    mutation GraphqlCreator($gImport: GenericImport!) {
      GraphqlCreator(
        a0: $gImport
      )
    }
  `
}
