import gql from 'graphql-tag'
import {PERSON_MODEL_FRAGMENT} from "@/modules/graphql/PersonModelFragment";
import {DOCUMENT_MODEL_FRAGMENT} from "@/modules/graphql/DocumentModelFragment";
import {MAP_STRING_INT_MODEL_FRAGMENT} from "@/modules/graphql/MapStringIntModelFragment";
import {HAND_OVER_MODEL_FRAGMENT} from "@/modules/graphql/HandOverModelFragment";
import {DIMENSION_MODEL_FRAGMENT} from "@/modules/graphql/DimensionModelFragment";

export const STATUS_MODEL_FRAGMENT = gql`
  ${PERSON_MODEL_FRAGMENT}
  ${DOCUMENT_MODEL_FRAGMENT}
  ${MAP_STRING_INT_MODEL_FRAGMENT}
  ${HAND_OVER_MODEL_FRAGMENT}
  ${DIMENSION_MODEL_FRAGMENT}

  
  fragment StatusModelFields on StatusModel {
    created
    documents {
      ...DocumentModelFields
    }
    pickedUpEmballage {
      ...MapStringIntModelFields
    }
    deliveredEmballage {
      ...MapStringIntModelFields
    }
    handover {
      ...HandOverModelFields
    }
    id
    identifier
    person {
      ...PersonModelFields
    }
    message
    location
    planningData {
      tripIdentifier
      stopIdentifier
      activityIdentifier
    }
    type
    dimensions {
      ...DimensionModelFields
    }
    state
}
`
