import gql from 'graphql-tag'

export const DIMENSION_MODEL_FRAGMENT = gql`
  fragment DimensionModelFields on DimensionModel {
    id
    attributeId
    unitCode
    value
  }
`
