import gql from 'graphql-tag'

export const DOCUMENT_MODEL_FRAGMENT = gql`
  fragment DocumentModelFields on DocumentModel {
    base64
    id
    meta
    mime
    name
    type
  }
`
