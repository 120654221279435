













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Popup extends Vue {
  @Prop() value: boolean
  @Prop() header: string
  @Prop({ default: false, type: Boolean }) hideCloseButton: boolean
}
