import gql from 'graphql-tag'

export const MAP_STRING_STRING_MODEL_FRAGMENT = gql`
  fragment MapStringStringModelFields on MapStringString {
    items {
      key
      value
    }
  }
`
