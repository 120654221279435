import i18n from '@/i18n'

export class Header {
  text: string
  value: string
  sortable: boolean

  constructor(text: string, value: string, sortable = true) {
    this.text = i18n.t(text).toString()
    this.value = value
    this.sortable = sortable
  }
}

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget
}
