import gql from 'graphql-tag'

export const PAIR_STATUS_REQUIREMENT_MODEL_FRAGMENT = gql`
  fragment PairStatusRequirementModelFields on StatusRequirementModel {
    requirement
    level
    text
    global
  }
`
