import gql from 'graphql-tag'

export const ADDRESS_MODEL_FRAGMENT = gql`
  fragment AddressModelFields on AddressModel {
    city
    country
    countryCode
    id
    name
    number
    street
    zipcode
    coordinates
  }
`
